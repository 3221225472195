<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item
        :to="{
          path: '/academic_Management/academic_font_management/academic_font_management_home',
        }"
        >学术系统</el-breadcrumb-item
      >
      <el-breadcrumb-item
        :to="{
          path: '/academic_Management/academic_font_management/academic_examination_management',
        }"
        >试卷管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>试卷详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class"
            >题型设置<span style="color: red"
              >(注*选择顺序即题型答题顺序)</span
            ></span
          >
        </el-col>
      </el-row>
      <el-row>
        <el-col
          :span="4"
          v-for="(questionItem, index) of examinationTypeList"
          :key="index"
        >
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            "
            @click="typeClicked(index)"
          >
            <img
              v-if="questionItem.discribePic"
              :src="questionItem.discribePic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <span style="font-size: 14px; text-align: center">{{
              questionItem.showName
            }}</span>
            <span style="font-size: 12px; text-align: center">{{
              questionItem.enName
            }}</span>
            <span style="font-size: 14px; text-align: center">{{
              questionItem.name
            }}</span>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col
          :span="4"
          v-for="(questionItem, index) of examinationTypeList"
          :key="index"
        >
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            "
          >
          <div style=" display: flex; flex-direction: row; align-items: center; justify-content: center;margin-top: 10px;">
            <div style="width: 40%;font-size: 14px; text-align: right;">题数：</div>
            <el-input style="width: 60%; " v-model="questionItem.questionNum"  @input.native="change($event,index)" placeholder="请输入题目数量" type="text" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
          </div>

          <div style=" display: flex; flex-direction: row; align-items: center; justify-content: center; margin-top: 10px;">
            <div style="width: 40%;font-size: 14px; text-align: right;">分数：</div>
            <el-input style="width: 60%;" v-model="questionItem.score"  @input.native="change($event,index)" placeholder="请输入题目分数" type="text" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
          </div>
        </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="end">
        <el-button type="primary" @click="confirmationQuestionType()"
          >确认题型</el-button
        >
        <el-button type="primary" plain @click="resetQuestionType()"
          >重置题型</el-button
        >
      </el-row>
    </el-card>
    <el-card style="margin-top: 15px" v-if="isShowQuestionList">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">题目列表</span>
        </el-col>
      </el-row>
      <el-tabs
        type="border-card"
        v-model="questionName"
        @tab-click="questionTypeChengeClick"
      >
        <el-tab-pane
          v-for="(item, index) in examinationTypeList"
          :key="index"
          :label="item.name"
          :name="item.name"
        >
        <div style="display: flex; flex-direction: row; align-items: center;">
          <el-button
              type="primary"
              icon="el-icon-edit"
              @click="editQuestionListClicked()"
              >编辑</el-button
            ><span style="color: red; margin-left: 10px; font-size: 20px;"
              >(注*不编辑题目系统将在题库随机抽取本组别题目)</span
          ></div>
        </el-tab-pane>
        <el-table
          header-cell-class-name="tableHeaderStyle"
          :data="questionListData"
          border
          stripe
        >
          <el-table-column label="序号" type="index"></el-table-column>
          <el-table-column label="单词" prop="answer"></el-table-column>
          <el-table-column label="题目分数" prop="fullScore"></el-table-column>
          <el-table-column label="组别" prop="level">
            <template slot-scope="scope">
              <span v-if="scope.row.level === 0">小初组</span>
              <span v-else-if="scope.row.level === 1">小中组</span>
              <span v-else-if="scope.row.level === 2">小高组</span>
              <span v-else-if="scope.row.level === 3">初中组</span>
              <span v-else>暂无</span>
            </template>
          </el-table-column>
          <el-table-column label="是否在词库内" prop="isindata">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.isindata == true"
                >词库内</el-tag
              >
              <el-tag type="danger" v-else-if="scope.row.isindata === false"
                >词库外</el-tag
              >
              <el-tag type="warning" v-else>未知</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="是否为测试题" prop="istest">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.istest == true">是</el-tag>
              <el-tag type="warning" v-else>否</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="单词类别"
            prop="wordCategory"
          ></el-table-column>
        </el-table>
      </el-tabs>
    </el-card>
    <!-- 题型弹窗 -->
    <el-dialog
      title="请选择题型"
      :visible.sync="examtionTypedialogVisible"
      width="50%"
    >
      <div class="examTypeback">
        <el-tag
          style="margin-bottom: 20px; margin-right: 20px"
          v-for="tag in questionTypeListData"
          :key="tag.id"
          :type="tag.type"
          @click="examTypeSelect(tag)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="examtionTypedialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="examtionTypedialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 所有题目列表弹窗 -->
    <el-dialog
      title="请选择题目"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
      :visible.sync="isShowAllQuestionListDialogVisible"
      width="70%"
    >
      <!-- 题库信息筛选 -->
      <el-card style="margin-top: 15px">
        <el-row class="title_row">
          <el-col>
            <span class="title_class">条件筛选</span>
          </el-col>
        </el-row>
        <el-form ref="queryFormRef" :model="queryListForm" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="单词" prop="answer">
                <el-input
                  v-model="queryListForm.answer"
                  placeholder="请输入单词"
                  clearable
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="含在词库中"
                label-width="130px"
                prop="isindata"
              >
                <el-select v-model="queryListForm.isindata" clearable>
                  <el-option
                    v-for="item in dict_isindata"
                    :key="item.dictCode"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="组别" prop="level">
                <el-select
                  v-model="queryListForm.level"
                  placeholder="请选择"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="item in dict_Group"
                    :key="item.dictCode"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row :gutter="20" type="flex" justify="end">
          <el-button type="primary" @click="getQuestionListData('search')"
            >查询</el-button
          >
          <el-button type="primary" plain @click="reset">重置</el-button>
        </el-row>
      </el-card>
      <!-- 题库列表 -->
      <el-card style="margin-top: 15px">
        <el-row class="title_row" align="middle" type="flex">
          <el-col :span="3">
            <span class="title-class title_class">题库列表</span>
          </el-col>
          <el-col :span="17">
          </el-col>
          <el-col :span="4" type="flex" justify="end">
            <el-button type="primary" @click="saveQuestiondata()"
            >保存当前题目</el-button
          >
          </el-col>
        </el-row>
        <el-table
          ref="questionListTable"
          header-cell-class-name="tableHeaderStyle"
          :data="allQuestionListData"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
          border
          stripe
        >
          <el-table-column
            type="selection"
            width="55"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column label="序号" type="index"></el-table-column>
          <el-table-column
            label="题型名称"
            prop="questionType"
            :formatter="questionTypeFormatter"
          ></el-table-column>
          <el-table-column label="题目答案" prop="answer"></el-table-column>
          <el-table-column label="题目分数" prop="fullScore"></el-table-column>
          <el-table-column label="imgUrl" prop="imgUrl"></el-table-column>
          <el-table-column label="组别" prop="level">
            <template slot-scope="scope">
              <span v-if="scope.row.level === 0">小初组</span>
              <span v-else-if="scope.row.level === 1">小中组</span>
              <span v-else-if="scope.row.level === 2">小高组</span>
              <span v-else-if="scope.row.level === 3">初中组</span>
              <span v-else>暂无</span>
            </template>
          </el-table-column>
          <el-table-column label="是否在词库内" prop="isindata">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.isindata == true"
                >词库内</el-tag
              >
              <el-tag type="danger" v-else-if="scope.row.isindata === false"
                >词库外</el-tag
              >
              <el-tag type="warning" v-else>未知</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="是否为测试题" prop="istest">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.istest == true">是</el-tag>
              <el-tag type="warning" v-else>否</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="单词类别"
            prop="wordCategory"
          ></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryListForm.pageNum"
          :page-sizes="[10, 20, 40, 80]"
          :page-size="queryListForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
        <el-row :gutter="20" type="flex" justify="center">
          <el-button type="primary" @click="saveQuestiondata()"
            >保存当前题目</el-button
          >
        </el-row>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
import { getQuestionPaperDetail, getExamDetail, questionTypeList, getQuestionList, saveQuestionType, createBathByQuestionType } from '@/http/api'

export default {
  data () {
    return {
      total: 0,
      multipleSelection: [],
      dict_isindata: this.$userInfo.dict_isindata(),
      dict_Group: this.$userInfo.dict_group(),
      questionName: '',
      isShowQuestionList: false,
      examtionTypedialogVisible: false,
      isShowAllQuestionListDialogVisible: false,
      queryForm: {
        questionPaperId: this.$route.query.examId,
        pageNum: 1,
        pageSize: 10
      },
      queryListForm: {
        pageNum: 1,
        pageSize: 10,
        questionPageId: this.$route.query.examId
      },
      questionListData: [],
      allQuestionListData: [],
      selectQuestionList: [],
      examTypeSelectIndex: 0,
      tagTypeList: [
        'success',
        '',
        'warning',
        'danger',
        ''
      ],
      questionTypeListData: [],
      examId: this.$route.query.examId,
      examinationTypeList: [
        {
          name: '',
          flag: ''
        },
        {
          name: '',
          flag: ''
        },
        {
          name: '',
          flag: ''
        },
        {
          name: '',
          flag: ''
        },
        {
          name: '',
          flag: ''
        }
      ]
    }
  },
  created () {
    this.getQuestionTypeList()
    this.getExamDetailData()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    questionTypeFormatter (data) {
      var temp = '未设置'
      for (const key in this.questionTypeListData) {
        if (Object.hasOwnProperty.call(this.questionTypeListData, key)) {
          const element = this.questionTypeListData[key]
          console.log('___________', element.flag, data)
          if (element.flag === data.questionType) {
            temp = element.name
          }
        }
      }
      return temp
    },
    // 获取题型列表
    getQuestionTypeList () {
      questionTypeList({ status: true }).then((res) => {
        this.questionTypeListData = res.data.map(item => {
          var indexs = Math.floor(Math.random() * (4 - 0 + 1)) + 0
          item.type = this.tagTypeList[indexs]
          return item
        })
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    getExamDetailData () {
      getExamDetail(this.$route.query.examId).then((res) => {
        console.log('res___', res)
        if (res.data.questionTypes) {
          var replaceArray = JSON.parse(res.data.questionTypes)
          console.log('replaceArray', replaceArray)
          this.examinationTypeList.splice(0, replaceArray.length, ...replaceArray)
          console.log(' this.examinationTypeList', this.examinationTypeList)
          this.isShowQuestionList = true
          this.questionName = this.examinationTypeList[0].name
          this.queryForm.questionType = this.examinationTypeList[0].flag
          this.queryListForm.questionType = this.examinationTypeList[0].flag
          this.getQuestionPaperDetail()
        }
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    getQuestionPaperDetail () {
      getQuestionPaperDetail(this.queryForm).then((res) => {
        this.questionListData = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 显示题型弹窗
    typeClicked (index) {
      this.examTypeSelectIndex = index
      this.examtionTypedialogVisible = true
    },
    examTypeSelect (selectType) {
      console.log()
      this.examtionTypedialogVisible = false
      selectType.index = this.examTypeSelectIndex
      this.examinationTypeList.splice(this.examTypeSelectIndex, 1, selectType)
    },
    // 设置题型时间
    change: function (e, index) {
      console.log(e, index)
    },
    // 确认题型点击事件
    confirmationQuestionType () {
      var examListt = []
      for (let index = 0; index < this.examinationTypeList.length; index++) {
        const element = this.examinationTypeList[index]
        console.log(element)
        if (element.flag !== '') {
          if (element.questionNum && Number(element.questionNum) > 0) {
            examListt.push({
              id: element.id,
              index: element.index,
              questionNum: element.questionNum,
              score: element.score
            })
          } else {
            return this.$message.error('请输入已选题型的题目数量')
          }
        }
      }
      var formData = {
        id: this.$route.query.examId,
        questionTypes: examListt
      }
      saveQuestionType(formData).then((res) => {
        this.$message.success('保存成功')
        this.isShowQuestionList = true
        this.questionName = this.examinationTypeList[0].name
        this.queryForm.questionType = this.examinationTypeList[0].flag
        this.queryListForm.questionType = this.examinationTypeList[0].flag
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 1000
        this.getQuestionPaperDetail()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 题型列表菜单点击
    questionTypeChengeClick (tab, event) {
      var selectIndex = Number(tab.index)
      this.queryForm.questionType = this.examinationTypeList[selectIndex].flag
      this.queryListForm.questionType = this.examinationTypeList[selectIndex].flag
      this.getQuestionPaperDetail()
    },
    // 重置题型点击事件
    resetQuestionType () {
      this.isShowQuestionList = false
      this.examinationTypeList = [
        {
          name: '',
          flag: ''
        },
        {
          name: '',
          flag: ''
        },
        {
          name: '',
          flag: ''
        },
        {
          name: '',
          flag: ''
        },
        {
          name: '',
          flag: ''
        }
      ]
    },
    // 编辑题列表
    editQuestionListClicked () {
      this.isShowAllQuestionListDialogVisible = true
      this.getQuestionListData()
    },
    getRowKey (row) {
      return row.id
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getQuestionListData('search')
    },
    handleSizeChange (newSize) {
      this.queryListForm.pageSize = newSize
      this.getQuestionListData()
    },
    handleCurrentChange (newCurPage) {
      this.queryListForm.pageNum = newCurPage
      this.getQuestionListData()
    },
    // 获取题列表
    getQuestionListData (type) {
      if (type === 'search') {
        this.queryListForm.pageNum = 1
        this.queryListForm.pageSize = 10
      }
      getQuestionList(this.queryListForm).then((res) => {
        this.allQuestionListData = res.data.list
        this.total = res.data.total
        this.allQuestionListData.forEach(row => {
          this.$nextTick(() => {
            if (row.isused) {
              this.$refs.questionListTable.toggleRowSelection(row, true)
            }
          })
        })
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    handleSelectionChange (val) {
      var selectList = []
      for (let index = 0; index < val.length; index++) {
        const element = val[index]
        const item = {}
        item.questionId = element.id
        item.id = element.isusedDetailId
        item.sort = index
        selectList.push(item)
      }
      this.selectQuestionList = selectList
    },
    // 保存当前已选中的题型的题目
    saveQuestiondata () {
      var formData = {
        questionPaperId: this.$route.query.examId,
        questionType: this.queryListForm.questionType,
        questions: this.selectQuestionList
      }
      console.log('formData', formData)
      createBathByQuestionType(formData).then((res) => {
        this.$message.success('保存成功')
        this.isShowAllQuestionListDialogVisible = false
        this.$refs.questionListTable.clearSelection()
        this.getQuestionPaperDetail()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.examTypeback {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.wordImg {
  width: 100px;
  height: 100px;
}
.avatar {
  width: 100px;
  height: 100px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px solid #8c939d;
}
</style>
